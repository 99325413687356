/* .leaflet-marker-icon {
  z-index: 10000000000000;
  border-radius: 5px;
  border: 2px solid #eee;
  padding:  2px;
} */

.detailsInfo {
  font-size: 10px;
  color: #333;

  font-family: Nunito;
}

.hidden {
  z-index: 0;
}

.temperature110 {
  background-color: rgb(255, 0, 0);
  border: 2px solid rgb(141, 68, 68);
}

.temperature100 {
  background-color: rgb(255, 123, 0);
  border: 2px solid rgb(216, 76, 76);
}

.temperature90 {
  background-color: rgb(255, 208, 0);
  border: 2px solid rgb(230, 155, 58);
}

.temperature80 {
  background-color: rgb(209, 224, 0);
  border: 2px solid rgb(207, 235, 115);
}

.temperature70 {
  background-color: rgb(104, 231, 0);
  border: 2px solid rgb(115, 216, 120);
}

.temperature60 {
  background-color: rgb(0, 202, 158);
  border: 2px solid rgb(167, 167, 167);
}

.temperature50 {
  background-color: rgb(0, 94, 122);
  border: 2px solid rgb(195, 195, 195);
}

.temperature40 {
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(255, 255, 255);
}

.temperature30 {
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(255, 255, 255);
}

.temperature20 {
  background-color: rgb(173, 173, 173);
  border: 2px solid rgb(255, 255, 255);
}

.temperature10 {
  background-color: rgb(131, 131, 131);
  border: 2px solid #eee;
}

.temperature0 {
  background-color: rgb(87, 87, 87);
  border: 2px solid rgb(255, 255, 255);
}

.temperature0 {
  background-color: rgb(77, 77, 77);
  border: 2px solid rgb(255, 255, 255);
}

.temperature-10 {
  background-color: rgb(78, 78, 78);
  border: 2px solid rgb(255, 255, 255);
}

.temperature-20 {
  background-color: rgb(80, 80, 80);
  border: 2px solid rgb(255, 255, 255);
}

.temperature-30 {
  background-color: rgb(71, 71, 71);
  border: 2px solid rgb(255, 255, 255);
}
