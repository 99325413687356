.searchResultsList {
  padding: 5px;
  border-radius: 4px;
  background-color: white;
  position: absolute;
  top: 90px;
  left: 20px;
  border: 1px solid #eee;
  box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.15);
}

.singleResult {
  background-color: white;
  opacity: 0.8;
  font-size: 12px;
  padding: 3px;
}

.singleResult:hover {
  background-color: #eee;
  cursor: pointer;
  border-radius: 3px;
}