.detailsPanel {
  background-color: white;
  position: absolute;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #eee;
  box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.15);
  font-size: 13px;
}

.recharts-cartesian-axis-tick {    
  font-size: 0.7rem;
  font-family: Nunito;
  stroke: #ccc;
}

.recharts-cartesian-axis-line {
  stroke: #ccc;
}

.recharts-cartesian-axis-tick-line {
  stroke: #ccc;
}

.floatRight {
  float: right;
}

.graphLegendItem {
  font-weight: 500;
  margin-left: 5px;
  font-size: 10px;
}

.header {
  font-weight: bold;
  font-size: 15px;
}