.paletteHeader {
  text-align: right;
  font-size: 12px;
  padding: 3px;
  min-width: 15px;
  min-height: 15px;
}

.paletteItem {
  text-align: right;
  font-size: 10px;
  padding: 3px;
  min-width: 15px;
  min-height: 15px;
  opacity: 0.5;
}

.palettePosition {
  position: absolute;
  bottom: 20px;
  left: 20px;
}