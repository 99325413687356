@font-face {
  font-family: 'Nunito';
  src: local('Nunito');
}

body {
  margin: 0;
  font-family: Nunito;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Nunito;
}

.nunito {
  font-family: Nunito;
}
